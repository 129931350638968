<template>
  <v-dialog
    scrollable
    transition="dialog-top-transition"
    open-delay="300"
    max-width="800px"
    v-model="dialog"
  >
    <template #activator="{ on }">
      <v-btn v-on="on" depressed fab small color="primary" class="ml-2">
        <icon-analytics-outline size="1.7em" />
      </v-btn>
    </template>

    <v-card>
      <v-toolbar color="grey lighten-3">
        <v-toolbar-title>{{ $t("price.history") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text v-if="dialog">
        <offer-prices-table :offer="offer" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text color="primary" @click="close">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type { Offer } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Vue, Prop } from "vue-property-decorator";

import OfferPricesTable from "@/modules/pricelists/components/OfferPricesTable.vue";

@Component({
  components: { OfferPricesTable },
})
export default class OfferPriceHistory extends Vue {
  @Prop(Object) readonly offer!: Offer;

  dialog = false;

  close() {
    this.dialog = false;
  }
}
</script>
