<template>
  <tr v-if="product">
    <td>
      <product-preview :product="product" />
    </td>
    <!-- <td>{{ product.code }}</td> -->
    <td>
      <form-field-date-picker
        v-model="validAt"
        :min="$dayjs().format('Y-MM-DD')"
        label="date.valid.from"
        single-line
      />
    </td>
    <td>
      <currency-select v-model="currencyId" single-line />
    </td>
    <td>
      <form-field-text
        v-model.number="price"
        :disabled="!currencyId"
        :label="$t('price')"
        input-type="number"
        single-line
        vid="price"
        @save="save"
        @input:debounce="onAutosave"
      />
    </td>
    <td>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            :color="btnColor"
            :disabled="invalid"
            :loading="loading"
            depressed
            fab
            small
            @click="save"
            v-on="on"
          >
            <icon-checkmark-circle-outline size="1.7em" />
          </v-btn>
        </template>
        <span v-text="$t('save')" />
      </v-tooltip>

      <offer-price-history v-if="hasPrices" :offer="offer" />
    </td>
  </tr>
</template>

<script lang="ts">
import type { OfferData, Product } from "@planetadeleste/vue-mc-shopaholic";
import { Offer } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Prop, Vue } from "vue-property-decorator";

import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import ProductPreview from "@/modules/products/components/ProductPreview.vue";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import OfferPriceHistory from "@/modules/pricelists/components/OfferPriceHistory.vue";
import { assign, chain, get, omit } from "lodash";
import type { PriceList } from "@planetadeleste/vue-mc-gw";

@Component({
  components: {
    CurrencySelect,
    ProductPreview,
    FormFieldDatePicker,
    OfferPriceHistory,
  },
})
export default class OfferRow extends Vue {
  @Prop(Number) readonly priceListId!: number;
  @Prop(Object) readonly priceList!: PriceList;
  @Prop({ type: Object, default: () => ({}) }) readonly product!: Product;
  @Prop(Boolean) readonly autosave!: boolean;

  offer: Offer = new Offer();
  loading = false;

  get hasPrices() {
    return this.offer.get("has_prices");
  }

  get currencyId() {
    return this.offer.get("currency_id");
  }

  set currencyId(iValue: number) {
    this.offer.set("currency_id", iValue);
    // this.save();
  }

  get price(): number {
    return this.offer.get(`${this.sPriceField}_value`, 0);
  }

  set price(iPrice: number) {
    this.offer.set(this.sPriceField, iPrice);
    this.offer.set(`${this.sPriceField}_value`, iPrice);
  }

  get validAt() {
    const sValidAt = this.offer.get("price_valid_at");
    return sValidAt ? this.$dayjs(sValidAt).format("YYYY-MM-DD") : "";
  }

  set validAt(sValue: string) {
    this.offer.set("price_valid_at", sValue);
  }

  get priceWithTax() {
    return this.priceList ? this.priceList.price_with_tax : false;
  }

  get sPriceField() {
    return this.priceWithTax ? "price_with_tax" : "price";
  }

  get btnColor() {
    return this.valid && this.dirty ? "primary" : "grey lighten-2";
  }

  get valid() {
    return !!this.currencyId;
  }

  get invalid() {
    return !this.valid;
  }

  get dirty() {
    return this.offer.isDirty(this.sPriceField);
  }

  mounted() {
    this.$nextTick(this.setOffer);
  }

  onAutosave() {
    if (this.autosave) {
      this.save();
    }
  }

  async save() {
    if (this.invalid) {
      return;
    }

    this.loading = true;

    if (!this.offer.quantity) {
      this.offer.set("quantity", 1);
    }

    this.offer.set("price_list_id", this.priceListId);
    this.offer.sync();
    this.offer
      .store()
      .then((response) => {
        if (response && response.getData()) {
          const obData = response.getData().data;
          this.offer.set(obData);
        }
      })
      .finally(() => {
        this.loading = false;
        this.offer.sync();
      });
  }

  setOffer() {
    if (this.product) {
      this.offer.set("product_id", this.product.id);
    }

    if (!this.product.offers.length) {
      return;
    }

    const obTempOfferData = this.product.offers[0];
    const obOfferData: OfferData | undefined = chain(this.product.offers)
      .filter((obData) => {
        const iPriceListId = get(obData, "price_list_id");
        return iPriceListId == this.priceListId || !iPriceListId;
      })
      .first()
      .value();

    const obItemOfferData = assign(
      { product_id: this.product.id },
      omit(obTempOfferData, ["price"]),
      obOfferData
    );

    this.offer.set(obItemOfferData);

    if (this.validAt && this.validAt !== this.offer.get("price_valid_at")) {
      this.offer.set("price_valid_at", this.validAt);
    }

    this.offer.sync();
  }
}
</script>
